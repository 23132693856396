import React, { useEffect, useState, useCallback, useRef } from "react"
import { FormattedMessage } from "react-intl"
import { useLocation } from "@reach/router"
import { parse } from "query-string"
import { connect } from "react-redux"
import { getProperties } from "../actions/propertiesAction"
import {
  clearPropertyFilters,
  setGeneralModal,
  setLoading,
} from "../actions/settingsActions"
import CloseFullScreen from "../assets/icons/closeFullScreen"
import FilterIcon from "../assets/icons/filterIcon"
import OpenFullScreen from "../assets/icons/openFullScreen"
import AdvancedFilters from "../components/filter/AdvancedFilters"
import AdvancedFiltersModal from "../components/filter/advancedFiltersModal"
import usePropertyFilters from "../components/hooksComponents/usePropertyFilters"
import Layout from "../components/layout"
import LoaderComponent from "../components/loader"
import Seo from "../components/seo"
import ClearFilterIcon from "../assets/icons/clearFilter"
import PropertyGrid from "../components/propertyGrid"
import SortComponent from "../components/sortComponent"
import { useInfiniteLoading } from "../components/hooksComponents/useInfiniteLoading"
import dataApi from "../_api/dataApi"
import noProps from "../assets/images/no-props.png"
import { error, scrollTop } from "../utils"
import Button from "../components/button"
import ViewModeButtons from "../components/viewModeButtons"
import useMediaQuery from "../components/hooksComponents/useMediaQuery"
import modernHome from "../assets/images/modernHomeForSale.svg"
import { Box, Grid } from "@material-ui/core"
import Burj from "../assets/images/burj-al-arab.png"
import { navigate } from "gatsby-link"

const HousingAssociation = ({
  setGeneralModal,
  loading,
  clearPropertyFilters,
  lang,
  sort,
  getProperties,
  setLoading,
}) => {
  const [sidebarExpanded, setSidebarExpanded] = useState(false)
  const [total, setTotal] = useState(0)
  const toggleSidebarExpanded = () => setSidebarExpanded(!sidebarExpanded)

  const location = useLocation()
  const { for: forTitle } = parse(location.search)

  const isSidebarHidden = useMediaQuery(`(max-width: 992px)`)

  let pageTitle = (
    <FormattedMessage
      defaultMessage="Housing Association"
      values={{
        search: "",
      }}
    />
  )

  if (forTitle === "rent") {
    pageTitle = <FormattedMessage defaultMessage="Properties For Rent" />
  } else if (forTitle === "sale") {
    pageTitle = <FormattedMessage defaultMessage="Properties For Sale" />
  }

  const filtersModalHandler = () => {
    setGeneralModal({
      open: true,
      Component: AdvancedFiltersModal,
      modalClassName: "full-modal",
      componentProps: { onApplyFilters: () => setReload(true) },
    })
  }

  const apiFilters = usePropertyFilters()

  const fetchProperties = useCallback(
    (config = {}) => {
      if (!config?.page || config?.page === 1) {
        setLoading(true)
      }
      return dataApi
        .getProperties({ ...config, filters: apiFilters, sort })
        .then(res => {
          setTotal(res?.total || 0)
          return Promise.resolve(res)
        })
        .catch(() => {
          error()
          return Promise.resolve(null)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [apiFilters, sort, getProperties, setLoading]
  )

  const { items, loadMoreRef, setReload, hasNext, loadNext } =
    useInfiniteLoading({
      getItems: fetchProperties,
      options: {
        loadingType: "infinite",
      },
    })

  useEffect(() => {
    !isSidebarHidden && setReload(true)
    scrollTop()
  }, [apiFilters, sort, lang])

  useEffect(() => {
    return () => clearPropertyFilters()
  }, [])

  return (
    <Layout>
      <Seo title="Off Plan Projects" />
      {loading && <LoaderComponent />}
      <div className="w-100 greyXLightBg d-flex">
        <div
          className="p-3 p-lg-5 flex-1"
          style={{ minWidth: "min(500px, 100%)" }}
        >
          {total <= 0 && !loading && (
            <div className="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
              <img className="w-50" src={noProps} alt="No Properties" />
              <div className="text-decorated mt-3">
                <h2 className="px36 black font-600">
                  <FormattedMessage defaultMessage="Opps No search Results" />
                </h2>
                <p className="px20 black mb-0">
                  <FormattedMessage defaultMessage="We did not find any search results" />
                </p>
              </div>
              <div className="d-block d-lg-none mt-2">
                <Button color="secondary" onClick={filtersModalHandler}>
                  <FormattedMessage defaultMessage="Try Again" />
                </Button>
              </div>
            </div>
          )}
          {total > 0 && (
            <div className="d-flex flex-column align-items-center">
              <div
                className="d-flex align-items-center justify-content-between mb-2 flex-wrap w-80"
                style={{ padding: "20px" }}
              >
                <div className="text-decorated">
                  <h2 className="black font-600 housingAssociation">{pageTitle}</h2>
                  <p className="px20 black mb-0">
                    {forTitle ? (
                      <FormattedMessage
                        defaultMessage="{count} Items"
                        values={{ count: total }}
                      />
                    ) : (
                      <FormattedMessage
                        defaultMessage="{count} Items"
                        values={{ count: total }}
                      />
                    )}
                  </p>
                </div>

                <div
                  className="d-flex justify-content-end flex-grow-1 flex-lg-grow-0 space-x-8 py-4 "
                  style={{ gap: "20px" }}
                >
                  <button
                    className="px28 px-3 darkGrey2 d-inline d-lg-none"
                    onClick={filtersModalHandler}
                  >
                    <FilterIcon />
                  </button>
                </div>
              </div>
              <div
                style={{
                  width: '80%',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#F8F8F8",
                  borderRadius: "16px",
                  paddingTop: "40px",
                  padding: "20px",
                }}
              >
                <img
                  alt="modern home"
                  src={modernHome}
                  width="100%"
                  height="auto"
                  class="br16 shadow-lg "
                />
              </div>
              <div
                className="pb-5 w-80"
                style={{
                  padding: "40px",
                }}
              >
                <Box>
                  <Grid
                    justifyContent="center"
                    alignContent="space-between"
                    alignItems="center"
                    container
                    spacing={8}
                    style={{ margin: "0px !important" }}
                  >
                    <Grid
                      item
                      style={{ display: "flex", justifyContent: "center" }}
                      xs={12}
                      sm={12}
                      lg={6}
                      xl={4}
                    >
                      <div className="relative action-hover">
                        <img
                          alt="burj"
                          src={Burj}
                          class="br16 shadow-lg offPlanImages"
                        />
                        <div
                          className="absolute bottom-0 bg-opacity d-flex justify-content-start align-items-center w-100"
                          style={{
                            borderBottomRightRadius: "16px",
                            borderBottomLeftRadius: "16px",
                          }}
                        >
                          <div className="signInBorder white m-4 py-3 h-100">
                            <h1 className="mx-4 px20 font-bold">
                              Bagdad New Line
                            </h1>
                            <div className="mx-4 px16">
                              Starting from 200 Million Syrian Pounds.
                            </div>
                            <div
                              className="d-flex justify-content-end align-items-end pointer"
                              onClick={() => navigate(`/app/association/1`)}
                            >
                              <button className="secondaryBg br8 p-2 my-2 white extra-info"
                              style={{display: 'flex', alignItems: 'center'}}>
                                <FormattedMessage defaultMessage="More details" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      style={{ display: "flex", justifyContent: "center" }}
                      xs={12}
                      sm={12}
                      lg={6}
                      xl={4}
                    >
                      <div className="relative action-hover">
                        <img
                          alt="burj"
                          src={Burj}
                          class="br16 shadow-lg offPlanImages"
                        />
                        <div
                          className="absolute bottom-0 bg-opacity d-flex justify-content-start align-items-center w-100"
                          style={{
                            borderBottomRightRadius: "16px",
                            borderBottomLeftRadius: "16px",
                          }}
                        >
                          <div className="signInBorder white m-4 py-3 h-100">
                            <h1 className="mx-4 px20 font-bold">
                              Bagdad New Line
                            </h1>
                            <div className="mx-4 px16">
                              Starting from 200 Million Syrian Pounds.
                            </div>
                            <div
                              className="d-flex justify-content-end align-items-end pointer"
                              onClick={() => navigate(`/app/association/1`)}
                            >
                              <button className="secondaryBg br8 p-2 my-2 white extra-info"
                              style={{display: 'flex', alignItems: 'center'}}>
                                <FormattedMessage defaultMessage="More details" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      style={{ display: "flex", justifyContent: "center" }}
                      xs={12}
                      sm={12}
                      lg={6}
                      xl={4}
                    >
                      <div className="relative action-hover">
                        <img
                          alt="burj"
                          src={Burj}
                          class="br16 shadow-lg offPlanImages"
                        />
                        <div
                          className="absolute bottom-0 bg-opacity d-flex justify-content-start align-items-center w-100"
                          style={{
                            borderBottomRightRadius: "16px",
                            borderBottomLeftRadius: "16px",
                          }}
                        >
                          <div className="signInBorder white m-4 py-3 h-100">
                            <h1 className="mx-4 px20 font-bold">
                              Bagdad New Line
                            </h1>
                            <div className="mx-4 px16">
                              Starting from 200 Million Syrian Pounds.
                            </div>
                            <div
                              className="d-flex justify-content-end align-items-end pointer"
                              onClick={() => navigate(`/app/association/1`)}
                            >
                              <button className="secondaryBg br8 p-2 my-2 white extra-info"
                              style={{display: 'flex', alignItems: 'center'}}>
                                <FormattedMessage defaultMessage="More details" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      style={{ display: "flex", justifyContent: "center" }}
                      xs={12}
                      sm={12}
                      lg={6}
                      xl={4}
                    >
                      <div className="relative action-hover">
                        <img
                          alt="burj"
                          src={Burj}
                          class="br16 shadow-lg offPlanImages"
                        />
                        <div
                          className="absolute bottom-0 bg-opacity d-flex justify-content-start align-items-center w-100"
                          style={{
                            borderBottomRightRadius: "16px",
                            borderBottomLeftRadius: "16px",
                          }}
                        >
                          <div className="signInBorder white m-4 py-3 h-100">
                            <h1 className="mx-4 px20 font-bold">
                              Bagdad New Line
                            </h1>
                            <div className="mx-4 px16">
                              Starting from 200 Million Syrian Pounds.
                            </div>
                            <div
                              className="d-flex justify-content-end align-items-end pointer"
                              onClick={() => navigate(`/app/association/1`)}
                            >
                              <button className="secondaryBg br8 p-2 my-2 white extra-info"
                              style={{display: 'flex', alignItems: 'center'}}>
                                <FormattedMessage defaultMessage="More details" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      style={{ display: "flex", justifyContent: "center" }}
                      xs={12}
                      sm={12}
                      lg={6}
                      xl={4}
                    >
                      <div className="relative action-hover">
                        <img
                          alt="burj"
                          src={Burj}
                          class="br16 shadow-lg offPlanImages"
                        />
                        <div
                          className="absolute bottom-0 bg-opacity d-flex justify-content-start align-items-center w-100"
                          style={{
                            borderBottomRightRadius: "16px",
                            borderBottomLeftRadius: "16px",
                          }}
                        >
                          <div className="signInBorder white m-4 py-3 h-100">
                            <h1 className="mx-4 px20 font-bold">
                              Bagdad New Line
                            </h1>
                            <div className="mx-4 px16">
                              Starting from 200 Million Syrian Pounds.
                            </div>
                            <div
                              className="d-flex justify-content-end align-items-end pointer"
                              onClick={() => navigate(`/app/association/1`)}
                            >
                              <button className="secondaryBg br8 p-2 my-2 white extra-info"
                              style={{display: 'flex', alignItems: 'center'}}>
                                <FormattedMessage defaultMessage="More details" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      style={{ display: "flex", justifyContent: "center" }}
                      xs={12}
                      sm={12}
                      lg={6}
                      xl={4}
                    >
                      <div className="relative action-hover">
                        <img
                          alt="burj"
                          src={Burj}
                          class="br16 shadow-lg offPlanImages"
                        />
                        <div
                          className="absolute bottom-0 bg-opacity d-flex justify-content-start align-items-center w-100"
                          style={{
                            borderBottomRightRadius: "16px",
                            borderBottomLeftRadius: "16px",
                          }}
                        >
                          <div className="signInBorder white m-4 py-3 h-100">
                            <h1 className="mx-4 px20 font-bold">
                              Bagdad New Line
                            </h1>
                            <div className="mx-4 px16">
                              Starting from 200 Million Syrian Pounds.
                            </div>
                            <div
                              className="d-flex justify-content-end align-items-end pointer"
                              onClick={() => navigate(`/app/association/1`)}
                            >
                              <button className="secondaryBg br8 p-2 my-2 white extra-info"
                              style={{display: 'flex', alignItems: 'center'}}>
                                <FormattedMessage defaultMessage="More details" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
                {hasNext && !loading && (
                  <button
                    ref={loadMoreRef}
                    className="w-100 px24 font-bold p-2 secondaryBg white br8"
                    onClick={() => loadNext()}
                  >
                    <FormattedMessage defaultMessage="Load More" />
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = ({
  data: { properties },
  settings: { lang, sort },
  shared: { loading },
}) => ({
  properties,
  loading,
  lang,
  sort,
})
export default connect(mapStateToProps, {
  clearPropertyFilters,
  setGeneralModal,
  getProperties,
  setLoading,
})(HousingAssociation)
